import React, { useState } from "react";

import Layout from "../components/layout";
import SmallBanner from "../components/smallBanner";

const Certificates = () => {
  const lang = "pl";

  const [activeDepartment, setActiveDepartment] = useState("1");
  const [openPopup, setOpenPopup] = useState(false);
  const [popupImage, setPopupImage] = useState("");

  const openPopupHandler = (image) => {
    setOpenPopup(true);
    setPopupImage(image);
  };

  const closePopupHandler = () => {
    setOpenPopup(false);
    setPopupImage("");
  };

  return (
    <Layout
      seoTitle="Certyfikaty"
      lang={lang}
      translationEN="/en/certificates/"
      translationDE="/de/zertifikate/"
    >
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SmallBanner title="Certyfikaty" lang={lang} />
      {openPopup ? (
        <div className="certificates__popup">
          <button
            onClick={() => closePopupHandler()}
            className="popup__background"
          />
          <div className="popup__content">
            <img className="popup__image" src={popupImage} alt="Certyfikat" />
            <button
              className="popup__close"
              onClick={() => closePopupHandler()}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <section className="certificates">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="certificates__tab-nav mb-50">
                <button
                  onClick={() => setActiveDepartment("1")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "1" ? "current" : ""
                  } `}
                >
                  PalettenWerk
                </button>
                <button
                  onClick={() => setActiveDepartment("2")}
                  className={`btn btn--plain btn--center ${
                    activeDepartment === "2" ? "current" : ""
                  } `}
                >
                  Palko
                </button>
              </div>
            </div>
            <div className="col-12">
              {activeDepartment === "1" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../assets/img/pwCertNew-1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/pwCertNew-1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                        Certyfikat Jakości ISO 9001:2015
                      </h3>
                      <p className="xs mb-20">
                        Posiadamy certyfikat jakości ISO od 2005 roku.
                        Wypracowane przez nas procedury i instrukcje
                        optymalizują wszystkie procesy w firmie począwszy od
                        zaopatrzenia w surowce, poprzez produkcję i sprzedaż.
                        Procesy te dostosowane są do specyfiki naszej branży.
                        Coroczne audyty w ramach uzyskanego certyfikatu
                        poświadczają zgodność praktyki ze stworzonymi przez nas
                        procedurami i instrukcjami.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <div className="item__content__actions">
                        <a
                          href
                          download
                          className="item__btn btn"
                          data-hover="Polityka ZSZ"
                          onClick={() =>
                            openPopupHandler(
                              require("../assets/img/pw_zsz.jpg")
                            )
                          }
                        >
                          Polityka ZSZ
                          <span className="btn-arrows"></span>
                        </a>
                        <a
                          href={require("../assets/img/pwCertNew.pdf")}
                          download
                          className="item__btn btn"
                          data-hover="Pobierz"
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/ISO14001.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/ISO14001.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Certyfikat Środowiskowy ISO 14001:2015
                      </h3>
                      <p className="xs mb-20">
                      Posiadamy certyfikat zaświadczający, że system zarządzania środowiskiem wdrożony 
                      przez organizację jest zgodny z normą ISO 14001:2015 i obejmuje swym zakresem 
                      strukturę organizacyjną, planowanie, rozłożenie odpowiedzialności oraz środki 
                      potrzebne do opracowywania, wdrażania i prowadzenia zarządzania w sposób 
                      uwzględniający obecne problemy związane z ochroną środowiska. System jest 
                      odpowiednio dostosowany do specyfiki naszej branży. Coroczne audyty w ramach
                      uzyskanego certyfikatu potwierdzajązgodność całego systemu zarządzania 
                      przedsiębiorstwem z normą.
                      </p>
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/ISO14001.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/pwCert2.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/pwCert2.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Certyfikat ISPM15 (IPPC)</h3>
                      <p className="xs mb-20">
                        Jako jedna z pierwszych firm otrzymaliśmy certyfikat
                        potwierdzający możliwość techniczną obróbki termicznej
                        palet zgodnie z Normą ISPM15, co umożliwia wysyłanie
                        towarów na naszych paletach poza Unię Europejską oraz do
                        wszystkich krajów, które wymagają przeprowadzenia takiej
                        obróbki (np. Ukraina, Norwegia, Wielka Brytania). Proces
                        ten zapobiega przenoszeniu się insektów do innych
                        ekosystemów.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/pwCert2.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/pwCert3.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Certyfikat PEFC</h3>
                      <p className="xs mb-20">
                        Certyfikat PEFC poświadcza, że drewno, które stosujemy w
                        naszej firmie, pochodzi z legalnego źródła, a także
                        zapewnia, że zasoby leśne są uzupełnianie po wycięciu
                        drzewa i użyciu go w celach przemysłowych. W naszej
                        firmie prowadzony jest nadzór nad przyjmowanym surowcem
                        drewnianym, tym samym działamy w ramach Zrównoważonego
                        Rozwoju oraz prowadzimy odpowiedzialną, skierowaną na
                        ekologię, politykę w zakresie gospodarki surowcami.
                      </p>
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/pwCert3.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/epalNew.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/epalNew.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Licencja EPAL</h3>
                      <p className="xs mb-20">
                        Jako jedna z pierwszych firm w Polsce, otrzymaliśmy w
                        roku 1998 licencję na produkcję palet w standardzie EPAL
                        i jednocześnie byliśmy współzałożycielem Polskiego
                        Komitetu Narodowego EPAL. Licencja ta gwarantuje jakość
                        wykonania palety 1200x800cm zgodnie z normą stworzoną
                        przez UIC. Jakość palety sprawdzana jest kilka razy w
                        miesiącu przez niezależne towarzystwo kontrolne.
                        Zapewnia to pełną wymienialność palety i umożliwia jej
                        sprzedaż na całym świecie. Produkujemy wszystkie typy
                        palet znajdujące się w portfolio EPAL.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/epalNew.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/pwCert5.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/pwCert5.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Świadectwo AEO</h3>
                      <p className="xs mb-20">
                        Od samego początku naszej działalności byliśmy
                        największym w Polsce eksporterem palet do Europy i poza
                        nią. Dzięki naszej wiarygodności uzyskaliśmy możliwość
                        dokonywania uproszczonych odpraw eksportowych, co w
                        znacznym stopniu przyśpieszyło i zoptymalizowało wysyłkę
                        naszych towarów za granicę,
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/AEO.png")}
                        alt="AEO"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/pwCert5.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {activeDepartment === "2" ? (
                <>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../assets/img/palkoCertNew-1.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/palkoCertNew-1.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">
                        Certyfikat Jakości ISO 9001:2015
                      </h3>
                      <p className="xs mb-20">
                        Posiadamy certyfikat jakości ISO od 2005 roku.
                        Wypracowane przez nas procedury i instrukcje
                        optymalizują wszystkie procesy w firmie począwszy od
                        zaopatrzenia w surowce, poprzez produkcję i sprzedaż.
                        Procesy te dostosowane są do specyfiki naszej branży.
                        Coroczne audyty w ramach uzyskanego certyfikatu
                        poświadczają zgodność praktyki ze stworzonymi przez nas
                        procedurami i instrukcjami.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/iso.jpg")}
                        alt="ISO"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/palkoCertNew.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../assets/img/ispm15_certificate_pl.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/ispm15_certificate_pl.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Certyfikat ISPM15 (IPPC)</h3>
                      <p className="xs mb-20">
                        Jako jedna z pierwszych firm otrzymaliśmy certyfikat
                        potwierdzający możliwość techniczną obróbki termicznej
                        palet zgodnie z Normą ISPM15, co umożliwia wysyłanie
                        towarów na naszych paletach poza Unię Europejską oraz do
                        wszystkich krajów, które wymagają przeprowadzenia takiej
                        obróbki (np. Ukraina, Norwegia, Wielka Brytania). Proces
                        ten zapobiega przenoszeniu się insektów do innych
                        ekosystemów.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/itd.png")}
                        alt="ITD"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/ispm15_certificate_pl.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(require("../assets/img/pwCert3.jpg"))
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/pwCert3.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Certyfikat PEFC</h3>
                      <p className="xs mb-20">
                        Certyfikat PEFC poświadcza, że drewno, które stosujemy w
                        naszej firmie, pochodzi z legalnego źródła, a także
                        zapewnia, że zasoby leśne są uzupełnianie po wycięciu
                        drzewa i użyciu go w celach przemysłowych. W naszej
                        firmie prowadzony jest nadzór nad przyjmowanym surowcem
                        drewnianym, tym samym działamy w ramach Zrównoważonego
                        Rozwoju oraz prowadzimy odpowiedzialną, skierowaną na
                        ekologię, politykę w zakresie gospodarki surowcami.
                      </p>
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/palkoCert3.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="certificates__item">
                    <button
                      className="item__image"
                      href
                      onClick={() =>
                        openPopupHandler(
                          require("../assets/img/palkoCert4.jpg")
                        )
                      }
                      style={{
                        backgroundImage: `url(${require(`../assets/img/palkoCert4.jpg`)})`,
                      }}
                    />
                    <div className="item__content">
                      <h3 className="mb-20">Licencja EPAL</h3>
                      <p className="xs mb-20">
                        Jako jedna z pierwszych firm w Polsce, otrzymaliśmy w
                        roku 1998 licencję na produkcję palet w standardzie EPAL
                        i jednocześnie byliśmy współzałożycielem Polskiego
                        Komitetu Narodowego EPAL. Licencja ta gwarantuje jakość
                        wykonania palety 1200x800cm zgodnie z normą stworzoną
                        przez UIC. Jakość palety sprawdzana jest kilka razy w
                        miesiącu przez niezależne towarzystwo kontrolne.
                        Zapewnia to pełną wymienialność palety i umożliwia jej
                        sprzedaż na całym świecie. Produkujemy wszystkie typy
                        palet znajdujące się w portfolio EPAL.
                      </p>
                      <img
                        className="item__logo"
                        src={require("../assets/img/quality-sign.png")}
                        alt="EPAL"
                      />
                      <div className="item__content__actions">
                        <a
                          className="item__btn btn"
                          href={require("../assets/img/palkoCert4.pdf")}
                          data-hover="Pobierz"
                          download
                        >
                          Pobierz
                          <span className="btn-arrows"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Certificates;
